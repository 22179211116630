import React, { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import NavComponent from "../components/Navigasi";
import { ShopContext } from '../context/shop-context';
import { useNavigate } from "react-router-dom"


const LupaPasswordComponent = () => {
  const [password, setPass] = useState ('');
  const [passwordbaru, setNewPass] = useState ('');
  const [error, setError] = useState ('');
  const [errorconfirm, setConfirmError] = useState ('');
  const {id} = useParams();
  const { updateEmailResetPassword } = useContext(ShopContext);

  const navigate = useNavigate()

  const ChangePassword = (e) => {
    const value = e.target.value
    setPass(value)
    setError ('')
  }

  const ChangeConfirmPassword = (e) => {
    const value = e.target.value
    setNewPass (value)
    setConfirmError ('')
      if (!value) {
        setConfirmError ('')
      } else if
          (password !== value) {
          setConfirmError ('*Konfirmasi Password Tidak Sama')
      }
  }

  const handleChangePass = async () => {
    if(password === '') {
      setConfirmError ('password tidak boleh kosong!')   
      return
    }
    const res = await updateEmailResetPassword({
      'email':id,
      'password':password
    })
    navigate('/login')
    console.log(res.data)
  }

  return (
    <>
    <NavComponent/>
      <div className="max-w-[400px] border border-slate-200 rounded-xl mx-auto shadow-md p-5 my-32 sm:max-w-lg">
        <h1 className="flex justify-center items-center font-bold text-3xl p-5">Reset Password</h1>
          <label htmlFor="lupapassword">
          <div>
            <span className="block font-semibold mb-1 text-slate-700 after:ml-1">Password Baru</span>
            <input
            type="password"
            id="password"
            placeholder="Password"
            className="px-3 py-2 border shadow rounded w-full block text-sm placeholder:text-slate-400 focus:outline-none focus:ring-1 focus:ring-slate-600 mb-3"
            value={password}
            onChange={ChangePassword} 
            required
          />
        </div>
        <div>
            <span className="block font-semibold mb-1 text-slate-700 after:ml-1">Konfirmasi Password Baru</span>
            <input
            type="password"
            id="konfirmasi"
            placeholder="Konfirmasi Password"
            className="px-3 py-2 border shadow rounded w-full block text-sm placeholder:text-slate-400 focus:outline-none focus:ring-1 focus:ring-slate-600 mb-3"
            value={passwordbaru}
            onChange={ChangeConfirmPassword} 
            required
            />

            {
              errorconfirm && (
                <p className="text-red-700"> {errorconfirm}</p>
              )
            }
          
        </div>
      </label>


          <button type="submit" onClick={handleChangePass} className="bg-slate-400 px-5 py-2 rounded-full text-white font-semibold hover:bg-slate-300 active:bg-slate-800 focus:ring-black">
            Reset
          </button>
      </div>
     </>
      
    )
}

export default LupaPasswordComponent;