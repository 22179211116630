import NavComponent from "../components/Navigasi2";
import TableSending from "../components/TableSending";

function SendOrder () {
    return (
        <>
        <NavComponent/>
        <TableSending/>
        </>
    )
}

export default SendOrder