import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import NavComponent from "../components/Navigasi";

function RegistrasiComponent() {
  const [formData, setFormData] = useState({
    phone_number: '',
    email: '',
    name: '',
    address: '',
    password: '',
    konfirmasi: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const navigate = useNavigate()

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('http://localhost:8000/register', formData);
      if(response.data.message === "Registration successful"){
        navigate("/login")
      } else {
        alert("Try Again, ", response.data.message)
      }
      // Update FetchComponent state or make another API request to fetch the updated data
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
    <NavComponent/>
    <div className="max-w-[400px] border border-slate-200 rounded-xl mx-auto shadow-md p-5 my-32 sm:max-w-lg">
      <h1 className="flex justify-center items-center font-bold text-3xl p-5">Mendaftarkan Akun</h1>
      <form onSubmit={handleSubmit}>
        <label htmlFor="phone_number">
          <span className="block font-semibold mb-1 text-slate-700 after:content-['*'] after:ml-1">Nomor Handphone</span>
          <input
            type="text"
            id="phone_number"
            placeholder="Nomor Handphone"
            className="px-3 py-2 border shadow rounded w-full block text-sm placeholder:text-slate-400 focus:outline-none focus:ring-1 focus:ring-slate-600 mb-3"
            value={formData.phone_number}
            onChange={handleChange} 
            required
          />
        </label>
        <label htmlFor="email">
          <span className="block font-semibold mb-1 text-slate-700 after:content-['*'] after:ml-1">Email</span>
          <input
            type="email"
            id="email"
            placeholder="Email"
            className="px-3 py-2 border shadow rounded w-full block text-sm placeholder:text-slate-400 focus:outline-none focus:ring-1 focus:ring-slate-600 mb-3"
            value={formData.email}
            onChange={handleChange} 
            required
          />
        </label>
        <label htmlFor="name">
          <span className="block font-semibold mb-1 text-slate-700 after:content-['*'] after:ml-1">Name</span>
          <input
            type="text"
            id="name"
            placeholder="Nama lengkap"
            className="px-3 py-2 border shadow rounded w-full block text-sm placeholder:text-slate-400 focus:outline-none focus:ring-1 focus:ring-slate-600 mb-3"
            value={formData.name}
            onChange={handleChange} 
            required
          />
        </label>
        <label htmlFor="address">
          <span className="block font-semibold mb-1 text-slate-700 after:content-['*'] after:ml-1">Alamat Lengkap</span>
          <input
            type="text"
            id="address"
            placeholder="RT/RW, Desa, Kota, Kabupaten, Kode Pos"
            className="px-3 py-2 border shadow rounded w-full block text-sm placeholder:text-slate-400 focus:outline-none focus:ring-1 focus:ring-slate-600 mb-3"
            value={formData.address}
            onChange={handleChange} 
            required
          />
        </label>
        <label htmlFor="password">
          <span className="block font-semibold mb-1 text-slate-700 after:content-['*'] after:ml-1">Password</span>
          <input
            type="password"
            id="password"
            placeholder="Password"
            className="px-3 py-2 border shadow rounded w-full block text-sm placeholder:text-slate-400 focus:outline-none focus:ring-1 focus:ring-slate-600 mb-3"
            value={formData.password}
            onChange={handleChange} 
            required
          />
        </label>
        <label htmlFor="konfirmasi">
          <span className="block font-semibold mb-1 text-slate-700 after:content-['*'] after:ml-1">Konfirmasi Password</span>
          <input
            type="password"
            id="konfirmasi"
            placeholder="Konfirmasi Password"
            className="px-3 py-2 border shadow rounded w-full block text-sm placeholder:text-slate-400 focus:outline-none focus:ring-1 focus:ring-slate-600 mb-3"
            value={formData.konfirmasi}
            onChange={handleChange} 
            required
          />
        </label>
        {/* Rest of the form fields */}

        
        <button type='submit'  className="bg-slate-400 px-5 py-2 rounded-full text-white font-semibold hover:bg-slate-300 active:bg-slate-800 focus:ring-black">
          Buat Akun
        </button>
        
      </form>
    </div>
  </>
  );
}

export default RegistrasiComponent;