import { Link } from "react-router-dom";
import React, { useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import NavComponent from "../components/Navigasi";
import { ShopContext } from "../context/shop-context";

function LoginComponent () {
  const {getTotalCartItem} = useContext(ShopContext);
  const totalCart = getTotalCartItem();

  const { cekLogin } = useContext(ShopContext);
    const [formData, setFormData] = useState({
        email: '',
        password: '',
      });
    
      const navigate = useNavigate()

      const handleChange = (e) => {
        setFormData({ ...formData, [e.target.id]: e.target.value });
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          const data = await cekLogin(formData);
          if(data) {
            navigate("/")
          } else {
            alert("Email / Password Yang Anda Masukan Salah")
          }
          // Update FetchComponent state or make another API request to fetch the updated data
        } catch (error) {
          console.error(error);
        }
      };

    return (
      <>
        <NavComponent/>
        <main className="min-h-[700px]">
        <div className="max-w-[400px] border border-slate-200 rounded-xl mx-auto shadow-md p-5 my-32 sm:max-w-lg">
        <h1 className="flex justify-center items-center font-bold text-2xl p-5">Masukkan Akun Yang Sudah Terdaftar</h1>
        <form onSubmit={handleSubmit}>
          <label htmlFor="email">
            <span className="block font-semibold mb-1 text-slate-700 after:content-['*'] after:ml-1">Email</span>
            <input
              type="email"
              id="email"
              placeholder="Email"
              className="px-3 py-2 border shadow rounded w-full block text-sm placeholder:text-slate-400 focus:outline-none focus:ring-1 focus:ring-slate-600 mb-3"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </label>
          <label htmlFor="password">
            <span className="block font-semibold mb-1 text-slate-700 after:content-['*'] after:ml-1">Password</span>
            <input
              type="password"
              id="password"
              placeholder="Password"
              className="px-3 py-2 border shadow rounded w-full block text-sm placeholder:text-slate-400 focus:outline-none focus:ring-1 focus:ring-slate-600 mb-3"
              value={formData.password}
              onChange={handleChange}
              required
            />
          </label>
  
           
          <button onClick={handleSubmit} type="submit" className="bg-slate-400 px-5 py-2 rounded-full text-white font-semibold hover:bg-slate-300 active:bg-slate-800 focus:ring-black">
            Masuk
          </button>
          
          <p className="pt-3">
          <span className="m-2">Belum Memiliki Akun? <Link to="/registrasi" className="m-1 text-blue-600">Daftar Sekarang</Link> </span>
          </p>

          <p className="pt-3">
          <span className="m-2">Lupa Password? <Link to="/ResetPassword" className="m-1 text-blue-600">Ganti Password</Link> </span>
          </p>
        </form>
      </div>
      
      <div className="flex justify-end p-5 text-gray-50 hover:text-gray-50 text-xl mr-10 mb-10">
        <Link to={"/login-admin"}>Login as Admin</Link>
      </div>
      </main>
      </>
    )
}
export default LoginComponent;