import NavComponent from "../components/Navigasi";
import DetailsComp from "../components/DetailsComp";



export default function DetailsOrder () {


    return(
        <>
            <NavComponent/>
            <DetailsComp/>
        </>
    )
}