import LupaPasswordComponent from "../components/LupaPassword";
import FooterComponent from "../components/Footer";
import NavComponent from "../components/Navigasi";

function lupapassword (){
    return (
        <>
        {/* <NavComponent/> */}

        <LupaPasswordComponent/>

        <FooterComponent/>
        </>
    )
}
export default lupapassword;