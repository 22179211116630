import React, { useContext, useState } from "react";

import { ShopContext } from "../../context/shop-context";
import NavComponent from "../../components/Navigasi";
import FooterComponent from "../../components/Footer";
import TanggalBulanTahun from "../../components/Tanggal";
import { CartItem } from "./cart-item";

import { useNavigate } from "react-router-dom";

import "./cart.css";

export const Cart = () => {
  const { name, address, phoneNumber, cartItems, getTotalCartAmount, getTotalCartWeight, getTotalItemPrice, getDatas, isLogedIn } = useContext(ShopContext);

  const [detail, setDetail] = useState(false);

  const Datas = getDatas();
  const totalAmount = getTotalCartAmount();
  const totalWeight = getTotalCartWeight();
  const totalPrice = getTotalItemPrice();

  function formatRupiah(number) {
    return new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(number);
  }

  const navigate = useNavigate();

  //const handleQRISPayment = () => {
  //if (isLogedIn) {
  //Implement QRIS payment flow and get QRIS image
  //Replace the following dummy code with actual QRIS generation and retrieval logic
  //const generatedQRIS = "qrisjon.png"; // Gantilah dengan gambar QRIS yang dihasilkan
  //setQRISImage(generatedQRIS); // Simpan gambar QRIS ke state
  ///sendOrderDataToApi(); // simpan data ke database

  //Setelah pembayaran berhasil, navigasi ke halaman "showQRIS"
  // navigate(`/showQRIS?image=${encodeURIComponent(generatedQRIS)}`);
  //} else {
  // alert("Silakan login terlebih dahulu");
  //}
  //}; 

  const handleQRISPayment = () => {
    if (isLogedIn) {
      navigate("/payment")
    } else {
      alert("Silakan login terlebih dahulu");
    }
  };

  const handleTransferPayment = () => {
    if (isLogedIn) {
      navigate("/transferpayment")
    } else {
      alert("Silakan login terlebih dahulu");
    }
  };

  console.log(cartItems)

  return (
    <>
      <NavComponent />
      <main className="flex flex-col md:flex-row min-h-[700px]">
        <div className={detail ? "cart pt-20 pb-60 w-2/3 flex justify-center items-center flex-col duration-150" : "cart pt-20 w-full duration-150 flex justify-center items-center flex-col"}>
          {totalAmount > 0 && isLogedIn ? (
            <>
              <div>
                <h1><b>Your Cart Items</b></h1>
              </div>
              <div className="cart">
                {Datas.map((product) => {
                  if (cartItems[product.id] == 1) {
                    return <CartItem data={product} />;
                  }
                  return true;
                })}
              </div>
              <div className="mb-40">
                <button className="bg-white hover-bg-slate-900 hover-text-white border-2 p-3 m-2 border-slate-900 rounded-xl" onClick={() => navigate("/product")}> More Product </button>
                <button className="bg-slate-900 hover-bg-white hover-text-slate-900 text-white border-2 p-3 m-2 border-slate-900 rounded-xl" onClick={() => setDetail(!detail)}> Detail &gt; </button>
              </div>
            </>
          ) : (
            <div className="checkout flex flex-col items-center">
              <h1> Your Shopping Cart is Empty</h1>
              <button className="bg-white hover-bg-slate-900 hover-text-white border-2 p-3 m-2 border-slate-900 rounded-xl" onClick={() => navigate("/product")}> See our Products </button>
            </div>
          )}
        </div>

        {detail ? (
          <aside className="md:w-1/3 md:absolute md:right-0 min-h-screen">
            <section id="bill" className="container mx-auto p-5 md:px-20 md:flex flex-col md:w-full gap-7">
              <div className="total-price-weight">
                <h3 className="text-xl font-semibold py-1">Alamat</h3>
                <p> Nama  : {name}</p>
                <p> Alamat  : {address} </p>
                <p> No Handphone : {phoneNumber}</p>
              </div>

              <div className="total-price-weight">
                <h3 className="text-xl font-semibold py-1">Rincian</h3>
                <p> Harga Barang  : {formatRupiah(totalAmount)}</p>
                <p> Berat Barang  : {totalWeight} Kg </p>
                <p> Total Pajak (10%) : {formatRupiah(totalAmount * 0.1)}</p>
              </div>
              <div className="delivery basis-1/3 md:mt-[-12px]">
                <h3 className="text-xl font-semibold py-3">Opsi Pengiriman</h3>
                <div className="opt-dev flex justify-between">
                  <div className="option">
                    <input className="p-2" type="checkbox" checked name="reguler" id="reg" />
                    <label htmlFor="reg">Reguler</label>
                  </div>
                  <p>{formatRupiah(80000)}</p>
                </div>
                <p className="text-center"> <TanggalBulanTahun /></p>
              </div>
              <div>
                <h3 className="text-xl font-semibold py-3">Jumlah Total</h3>
                <p> Total Harga Keseluruhan :  {formatRupiah(totalPrice)} </p>
              </div>
              <hr className="h-1 bg-black" />
              <div className="total cart">
                <button onClick={handleQRISPayment} className="bg-slate-900 hover-bg-slate-900 hover-text-slate-200 text-lg font-semibold text-white border-2 p-3 mt-4 px-10 border-slate-900 rounded-xl">
                  Bayar Sekarang
                </button>

              </div>
            </section>
          </aside>
        ) : (
          <div></div>
        )}
      </main>

      <FooterComponent />
    </>

  );
};