import React, { useState, useEffect } from 'react';
import axios from 'axios';

const TablePending = () => {
  const [datas, setDatas] = useState([]);
  const [bukti, setBukti] = useState("");

  const handleGetData = () => {
    axios.get('http://localhost:8000/orders')
      .then(response => {
        setDatas(response.data);
        console.log(response.data);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }

  // const handleUpdate = (id, status, order) => {
  //   const updatedData = { ...order, status: status };

  //   axios.put(`http://localhost:8000/orders/${id}`, updatedData)
  //     .then(response => {
  //       // Lakukan pembaruan state atau tindakan lainnya
  //       console.log('Data updated:', response.data);
  //     })
  //     .catch(error => {
  //       console.error('Error:', error);
  //     });
  // };

  // const handleDelete = (id) => {
  //   axios.delete(`http://localhost:8000/orders/${id}`)
  //     .then(response => {
  //       // Lakukan pembaruan state atau tindakan lainnya
  //       console.log('Data deleted:', response.data);
  //     })
  //     .catch(error => {
  //       console.error('Error:', error);
  //     });
  // };

  const handleGetBukti = () => {
    fetch('http://127.0.0.1:8000/photos', {
      method: 'GET',
    })
      .catch(error => {
        console.error('Error:', error);
      });
  }

  const handleUpdate = (id, status, order) => {
    const updatedData = {
      id: id,
      status_pesanan: status,
    }
    axios.post(`http://localhost:8000/update-order`, updatedData)
      .then(response => {
        alert('Status Pesanan Berhasil Diperbarui')
        window.location.reload()
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  const handleDelete = (id) => {
    const updatedData = {
      id: id,
      status_pesanan: "Dibatalkan",
    }
    axios.post(`http://localhost:8000/update-order`, updatedData)
      .then(response => {
        alert('Pesanan Berhasil Dibatalkan')
        window.location.reload()
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  const reduceDataInAPI = async (id) => {
    try {
      const response = await axios.get(`http://localhost:3004/products/${id}`);

      if (response.status === 200) {
        const data = response.data;
        console.log(data[1]);

        data.stock -= 1;

        const updateResponse = await axios.put(`http://localhost:3004/products/${id}`, data);

        if (updateResponse.status === 200) {
          console.log('Data berhasil dikurangi');
        } else {
          console.error('Gagal memperbarui data');
        }
      } else {
        console.error('Gagal mengambil data');
      }
    } catch (error) {
      console.error('Terjadi kesalahan:', error);
    }
  };

  useEffect(() => {
    handleGetData();
  }, []);

  useEffect(() => {
    handleGetBukti();
  }, []);

  const handleOpenBukti = (imageName) => {
    console.log(imageName)
    const imageUrl = `http://localhost:8000/images/${imageName.replace('bukti/', '').replace('bukti\\', '')}`;
    window.open(imageUrl, '_blank');
  };

  return (
    <div className="relative overflow-x-auto max-w-[90%] justify-center items-center mx-auto my-10">
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" className="px-6 py-3">
              No
            </th>
            <th scope="col" className="px-6 py-3">
              Nama
            </th>
            <th scope="col" className="px-6 py-3">
              Nomor Pesanan
            </th>
            <th scope="col" className="px-6 py-3">
              Nama Barang
            </th>
            <th scope="col" className="px-6 py-3">
              Jumlah
            </th>
            <th scope="col" className="px-6 py-3">
              Harga Total
            </th>
            <th scope="col" className="px-6 py-3">
              Alamat
            </th>
            <th scope="col" className="px-6 py-3">
              Status
            </th>
            <th scope="col" className="px-6 py-3">
              Bukti
            </th>
            <th scope="col" className="px-6 py-3">
              Opsi
            </th>
          </tr>
        </thead>
        <tbody>
          {datas.map((order, index) => {
            if (order.status === "menunggu konfirmasi admin") {
              return (
                <tr
                  key={index}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                >
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {index + 1}
                  </th>
                  <td className="px-6 py-4">{order.username}</td>
                  <td className="px-6 py-4">{order.id}</td>
                  <td className="px-6 py-4">{order.barang}</td>
                  <td className="px-6 py-4">{order.jumlah_barang}</td>
                  <td className="px-6 py-4">{order.total}</td>
                  <td className="px-6 py-4">{order.alamat}</td>
                  <td className="px-6 py-4">{order.status}</td>
                  <td className="px-6 py-4">
                  <button
                      onClick={() => handleOpenBukti(order.bukti)} className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-2 border border-gray-400 rounded shadow"
                    >
                      Bukti Transfer
                    </button>
                  </td>
                  <td className="px-6 py-4 flex">
                    {order.status === "Pesanan Selesai" ? (
                      <button
                        className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-2 border border-gray-400 rounded shadow"
                        onClick={() => handleUpdate(order.id, "Pesanan Selesai", order)}
                      >
                        Pesanan Sampai
                      </button>
                    ) : (
                      <button
                        className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-2 border border-gray-400 rounded shadow"
                        onClick={() => {
                          reduceDataInAPI(order.goods_id, order.jumlah_barang)
                          handleUpdate(order.id, "Pesanan Dikirim", order)
                        }

                        }
                      >
                        ACC Pesanan
                      </button>
                    )}
                    <p class="pl-2">
                      <button
                        class="pl-5" className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-2 border border-gray-400 rounded shadow"
                        onClick={() => handleDelete(order.id)}
                      >
                        Hapus Pesanan
                      </button>
                    </p>
                  </td>
                </tr>
              );
            }
            return null; // Menambahkan agar React tidak memberikan warning tentang elemen yang tidak unik
          })}
        </tbody>
      </table>
    </div>
  );
};

export default TablePending