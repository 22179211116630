import { Link } from "react-router-dom";
import React, { useState, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import NavComponent from "../components/Navigasi";
import { ShopContext } from "../context/shop-context";


function AccLoginComponent () {
	const {isLogedIn} = useContext(ShopContext);
	const [confirm, setConfirm] = useState ('');
  	const [cancel, setCancle] = useState ('');

  	const navigate = useNavigate()

  	const handleConfirm = () => {
    	if (!confirm) 
    		navigate("/login")
        }

  const handleCancel = () => {
    if (!cancel) 
      navigate("/product")
        
       }

return (
    <>
    <NavComponent/>
      <div className="max-w-[400px] border border-slate-200 rounded-xl mx-auto shadow-md p-5 my-32 sm:max-w-lg">
        <h1 className="flex justify-center items-center font-bold text-3xl p-5">Apakah Anda Yakin Ingin Login?</h1>

        <button onClick={handleConfirm} type="submit" className="bg-slate-400 px-5 py-2 rounded-full text-white font-semibold hover:bg-slate-300 active:bg-slate-800 focus:ring-black">
            Confirm
         </button>
         <button onClick={handleCancel} type="submit" className="bg-slate-400 px-5 py-2 rounded-full text-white font-semibold hover:bg-slate-300 active:bg-slate-800 focus:ring-black">
            Cancel
         </button>
        
      </div>
     </>
	)
}
     
export default AccLoginComponent;