import React, { useContext, useState } from 'react';
import NavComponent from "../components/Navigasi";
import { ShopContext } from '../context/shop-context';

const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const {sendEmailResetPassword} = useContext(ShopContext);

  const handleSubmit = async () => {
    const res = await sendEmailResetPassword({
      'email':email
    })
    if(res.data.status) alert('link konfirmasi reset password telah dikirim ke email kamu!')
    else alert('email invalid')
  };

  return (
    <>
    <NavComponent/>
      <div className="max-w-[400px] border border-slate-200 rounded-xl mx-auto shadow-md p-5 my-32 sm:max-w-lg">
        <h1 className="flex justify-center items-center font-bold text-3xl p-5">Masukan Email</h1>
          <label htmlFor="resetpassword">
          <div>
            <span className="block font-semibold mb-1 text-slate-700 after:ml-1">Kirim Konfirmasi Reset Password</span>
            <input
            type="email"
            id="email"
            placeholder="Email"
            className="px-3 py-2 border shadow rounded w-full block text-sm placeholder:text-slate-400 focus:outline-none focus:ring-1 focus:ring-slate-600 mb-3"
            value={email}
            onChange={(e) => setEmail(e.target.value)} 
            required
          />
        </div>
      </label>


          <button type="submit" onClick={handleSubmit} className="bg-slate-400 px-5 py-2 rounded-full text-white font-semibold hover:bg-slate-300 active:bg-slate-800 focus:ring-black">
            Reset
          </button>
      </div>
     </>
      
    )
}

export default ResetPassword;