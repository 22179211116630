import NavComponent from "../components/Navigasi2";
import TableDone from "../components/TableDone";

function DoneOrder () {
    return (
        <>
        <NavComponent/>
        <TableDone/>
        </>
    )
}

export default DoneOrder