import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { ShopContext } from "../context/shop-context";

export default function DetailsComp() {
  const [orderData, setOrderData] = useState([]);
  const { isLogedIn, name } = useContext(ShopContext);

  useEffect(() => {
    // Fungsi untuk mengambil data pesanan dari server
    const fetchData = async () => {
      try {
        const response = await axios.get('http://localhost:8000/orders');
        setOrderData(response.data);
      } catch (error) {
        if (error.response?.status === 404) {
          // Handle 404 error
        } else {
          // Handle other errors
        }
      }
    };

    // Panggil fungsi fetchData saat komponen dimuat
    fetchData();
  }, []); // Dependensi kosong agar hanya dipanggil saat komponen dimuat

  // Fungsi untuk menghapus pesanan
  const handleUpdate = (id, status, order) => {
    const updatedData = { ...order, status: status };

    axios.put(`http://localhost:8000/orders/${id}`, updatedData)
      .then(response => {
        // Lakukan pembaruan state atau tindakan lainnya
        console.log('Data updated:', response.data);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  return (
    <div className="p-10">
      {isLogedIn ? orderData.map((data) => {
        if (data.username === name) {
          return (
            <div
              key={data.id}
              className="bg-white w-1/2 p-6 rounded-lg shadow-md mb-5 mx-auto text-center"
            >
              <img
                src={data.img_url}
                alt={data.barang}
                className="w-32 h-32 mx-auto mb-4 object-cover"
              />
              <h1 className="text-xl font-semibold">{data.barang}</h1>
              <h1 className="text-base mb-2 mt-2">Penerima: {data.username}</h1>
              <h1 className="mb-5">Total Harga: {data.total}</h1>
              <button
                className={`px-4 py-2 ${data.status === "Pesanan Dikirim" ? "bg-blue-500" : "bg-gray-500"} text-white rounded-full`}
                onClick={() =>
                  data.status === "Pesanan Dikirim" && handleUpdate(data.id, "Pesanan Selesai", data)
                }
              >
                {data.status}
              </button>

            </div>
          );
        }
        return null; // Pastikan untuk mengembalikan null jika kondisi tidak terpenuhi
      }) : <div>Mohon Untuk Login Terlebih Dahulu...</div>}
    </div>
  );
}