// import { Link } from "react-router-dom";
import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import NavComponent from "../components/Navigasi";


function LoginAdmin () {
    const [formData, setFormData] = useState({
        email: '',
        password: '',
      });
          
      const handleChange = (e) => {
        setFormData({ ...formData, [e.target.id]: e.target.value });
      };

    
      const handleSubmit = (e) => {
        e.preventDefault();

        if(formData.email === "admin@gmail.com" && formData.password === "admin123") {
          navigate("/dashboard")
        } else {
          alert("You are NOT admin")
          navigate("/login-admin")
        }
    
        // try {
        //   await axios.post('http://localhost:8000/login', formData);
        //   console.log('Data successfully submitted');
        //   // Update FetchComponent state or make another API request to fetch the updated data
        // } catch (error) {
        //   console.error(error);
        // }
      };

      const navigate = useNavigate()
    return (
      <>
         <NavComponent/>
        
        <div className="max-w-[400px] border border-slate-200 rounded-xl mx-auto shadow-md p-5 my-32 sm:max-w-lg">
        <h1 className="flex justify-center items-center font-bold text-2xl p-5">Login as Admin</h1>
        <form onSubmit={handleSubmit}>
          <label htmlFor="email">
            <span className="block font-semibold mb-1 text-slate-700 after:content-['*'] after:ml-1">Email</span>
            <input
              type="email"
              id="email"
              placeholder="Email"
              className="px-3 py-2 border shadow rounded w-full block text-sm placeholder:text-slate-400 focus:outline-none focus:ring-1 focus:ring-slate-600 mb-3"
              value={formData.email}
              onChange={handleChange}
              autoComplete="off"
              required
            />
          </label>
          <label htmlFor="password">
            <span className="block font-semibold mb-1 text-slate-700 after:content-['*'] after:ml-1">Password</span>
            <input
              type="password"
              id="password"
              placeholder="Password"
              className="px-3 py-2 border shadow rounded w-full block text-sm placeholder:text-slate-400 focus:outline-none focus:ring-1 focus:ring-slate-600 mb-3"
              value={formData.password}
              onChange={handleChange}
              autoComplete="off"
              required
            />
          </label>
          {/* Rest of the form fields */}
  
            
            <button onClick={handleSubmit} className="bg-slate-400 px-5 py-2 rounded-full text-white font-semibold hover:bg-slate-300 active:bg-slate-800 focus:ring-black">
            Masuk
          </button>
            
        </form>
      </div>
      
      {/* <div className="flex justify-end p-5 text-blue-400 hover:text-blue-950 text-xl mr-10 mb-10">
        <Link to={"/login"}>Login as user</Link>
      </div> */}
      </>
    )
}
export default LoginAdmin;