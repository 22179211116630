import NavComponent from "../components/Navigasi";
import LoginComponent from "../components/Login";
import FooterComponent from "../components/Footer";
function LoginPage() {
    return (
        <>
        {/* <NavComponent/> */}
        <LoginComponent/>
        <FooterComponent/>
        </>
    )
}

export default LoginPage;