import React, { useContext } from "react";
import { ShopContext } from "../../context/shop-context";

export const CartItem = (props) => {
  const { id, name, desc, price, img_url, weight, stock } = props.data;
  const { cartItems, addToCart, removeFromCart, updateCartItemCount } =
    useContext(ShopContext);
  function formatRupiah(number) {
      return new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(number);
    }
  return (
    <div className="cartItem flex flex-col md:flex-row">
      <img src={img_url} alt={name} />      
        <div className="description">
              <p className="font-bold text-2xl">{name}</p>
              <p className="py-2 font-normal">{desc}</p>
              <p class="font-medium"> Harga: {formatRupiah(price)}</p>
              <p class="font-medium"> Stok: {stock}</p>
              <p class="font-medium"> Berat Barang: {weight} Kg</p>
              <div className="countHandler">
                <button onClick={() => removeFromCart(id)}> - </button>
                <input
                  value={cartItems[id]}
                  onChange={(e) => updateCartItemCount(Number(e.target.value), id)}
                />
                <button onClick={() => addToCart(id)}> + </button>
              </div>
            </div>
    </div>
  );
};
