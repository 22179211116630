function MainComponent () {
    return (
         <main className="min-h-screen bg-visi bg-cover bg-no-repeat ">
         <img
            src="https://images.unsplash.com/photo-1639259885918-b8ee9b8374b8?q=80&w=1567&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            class="h-96 w-full object-cover rounded-xl shadow-none transition-shadow duration-300 ease-in-out hover:shadow-lg hover:shadow-black/30"
            alt="" />

         <div className="flex justify-center items-center text-3xl py-9 font-mono font-semibold">
          TENTANG KAMI
        </div>
          <p className="decoration-black px-10 lg:px-15 p-3 pb-14 text-base text-center lg:text-lg mt-3 font-medium">
          CV. Joni Gencar Abyakta adalah sebuah perusahaan yang bergerak dibidang furniture yang memproduksi
          berbagai jenis mebel dan furniture untuk kebutuhan rumah anda. Berdiri sejak 2007 dan berlokasi
          di kecamatan Gunungjati Kabupaten Cirebon.
        </p>

        <div>
        <img
            src="https://images.unsplash.com/photo-1577017050748-389f0021d860?q=80&w=1482&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            class="h-80 w-full object-none object-right-bottom px-10 lg:px-16 p-3 pb-14 text-base text-center lg:text-lg"
        />
        </div>

        <div className="flex justify-start p-10 items-center text-3xl pb-1 font-sans font-semibold">
          VISI
        </div>
        <p className="decoration-black px-10 lg:px-15 p-3 pb-8 pr-64 text-base text-justify lg:text-lg mt-3 font-medium">
          Dengan berlandaskan tekad, semangat, dan kekeluargaan sehingga menghasilkan produk-produk furniture
          yang berkualitas serta memberikan pelayanan yang terbaik.
        </p>

        <div className="flex justify-start p-10 items-center text-3xl pb-1 font-sans font-semibold">
          MISI
        </div>
        <p className="decoration-black px-10 lg:px-15 p-3 pb-8 pr-64 text-base text-justify lg:text-lg mt-3 font-medium">
          <ul class="list-disc list-inside"> 
            <li> Mengelola dan mengembangkan Sumber Daya Manusia yang profesional, </li>
            <li> Menjalin kerjasama dengan para mitra bisnis untuk menghasilkan keuntungan bersama jangka panjang, </li>
            <li> Membangun budaya kerja yang baik dan mendukung kesejahteraan karyawan untuk menuju kualitas hidup yang lebih baik. </li>
          </ul>
        </p>

        <div className="flex justify-center items-center text-3xl py-5 font-mono font-semibold">
          Alur Pemesanan Barang
        </div>
        <div class="flex justify-center items-center pl-10 pb-10 p-10">
          <img src="/img/flow.png" alt="flow"/>
        </div>
        <div className="alur flex flex-row font-semibold text-base pb-10">
          <p className="text-center pl-20">Pembeli</p>
          <p className="text-center pl-20">Pembeli Mengakses Website <br/> Melalui Komputer</p>
          <p className="text-center pl-14">Pembeli Melihat Barang <br/> & Memasukan Barang <br /> ke Keranjang</p>
          <p className="text-center pl-10">Pembeli Melakukan Checkout <br/> Pada Barang</p>
          <p className="text-center pl-12">Penjual Mengirim Barang <br/> Kepada Pembeli</p>
          <p className="text-center pl-10">Barang Sampai & <br/> Diterima Oleh Pembeli</p>
        </div>
      
      
        <div className="flex flex-col text-center p-10 text-2xl pb-1 font-semibold border-2 p-2 m-2 border-slate-900 rounded-xl">
          Mohon Dibaca!!
        

            <div className="text-center decoration-black text-base lg:text-lg mt-3 font-medium">
              <p>*Pemesanan Hanya Berlaku Untuk Wilayah Cirebon dan Sekitarnya</p> 
            </div>
         </div>
      
      </main>
    )
}

export default MainComponent;