import React, { useContext, useState } from "react";
import axios from 'axios';

import { ShopContext } from "../../context/shop-context";
import { useNavigate } from 'react-router-dom';
import NavComponent from "../../components/Navigasi";
import FooterComponent from '../../components/Footer';

function PaymentPageComponent() {
  const { email, phoneNumber, name, cartItems, address, getTotalCartAmount, getTotalCartWeight, getTotalItemPrice, getCartName, emptyCart } = useContext(ShopContext);

  const totalAmount = getTotalCartAmount();
  const totalWeight = getTotalCartWeight();
  const totalPrice = getTotalItemPrice();
  const barang = getCartName();
  const [bukti, setBukti] = useState("");
  const [qris, setQris] = useState(false);
  const [transfer, setTransfer] = useState(false);
  const [selectedImage, setSelectedImage] = useState(
    'https://images.pexels.com/photos/5650028/pexels-photo-5650028.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
  );

  const handleSubmit = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };

  const handleUpload = () => {
    const fileInput = document.getElementById('fileInput');
    const file = fileInput.files[0];

    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      console.log(file)

      fetch('http://127.0.0.1:8000/photos', {
        method: 'POST',
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          setBukti(data.file_path)
          console.log('Upload successful:', data);
          alert('Bukti Transfer Berhasil Terupload, Silahkan Selesaikan Pesanan')
        })
        .catch((error) => {
          console.error('Error uploading file:', error);
        });
    } else {
      console.error('No file selected');
    }
  };

  const generateRandomNumber = () => {
    return Math.floor(Math.random() * 10000);
  };

  function formatRupiah(number) {
    return new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(number);
  }

  const sendOrderDataToApi = () => {
    console.log(barang)
    const orderData = barang.map(item => {
      // const order = {
      //   id: generateRandomNumber(),
      //   goods_id: item.id,
      //   username: name,
      //   alamat: address,
      //   jumlah_barang: cartItems[item.id],
      //   harga: totalAmount,
      //   berat: totalWeight,
      //   total: formatRupiah(totalPrice),
      //   barang: item.name,
      //   img_url: item.img_url,
      //   bukti: bukti,
      //   status: "menunggu konfirmasi admin"
      // }
      console.log(bukti)

      const order = {
        goods_id: item.id,
        username: name,
        alamat: address,
        jumlah_barang: cartItems[item.id],
        harga: totalAmount,
        berat: totalWeight,
        total: formatRupiah(totalPrice),
        barang: item.name,
        img_url: item.img_url,
        bukti: bukti,
        email: email,
        phone_number: phoneNumber,
      };

      // return axios.post('http://localhost:3004/orders', order)
      return axios.post('http://localhost:8000/create-order', order)
        .then(response => {
          console.log('Post created:', response.data);
          console.log(order);
        })
        .catch(error => {
          console.error('Error:', error);
        });
    });
    return orderData;
  };

  const navigate = useNavigate();

  const cancel = () => {
    navigate("/shopping_cart")
    alert("Silahkan Melihat Produk Kami Lagi");
  };

  const payment = async () => {
    await sendOrderDataToApi();
    await emptyCart();
    navigate("/cart")
  };

  return (
    <>
      <NavComponent />
      <h2 className="flex justify-center items-center font-bold text-2xl pt-10">Silahkan Pilih Metode Pembayaran</h2>
      <main className="min-h-[700px]">
        <div className={qris ? "mb-1 cart pt-8 flex justify-center items-center flex-col duration-300" : "cart pt-8 w-full duration-300 flex justify-center items-center flex-col"}>
          <button className="bg-slate-900 hover-bg-white hover-text-slate-900 text-white border-2 p-3 m-2 border-slate-900 rounded-xl" onClick={() => setQris(!qris)}> Bayar QRIS &gt; </button>
        </div>

        <div className={transfer ? "mb-1 cart pt-4 flex justify-center items-center flex-col duration-300" : "cart pt-8 w-full duration-300 flex justify-center items-center flex-col"}>
          <button className="bg-slate-900 hover-bg-white hover-text-slate-900 text-white border-2 p-3 m-2 border-slate-900 rounded-xl" onClick={() => setTransfer(!transfer)}> Bayar Transfer Bank &gt; </button>
        </div>

        {qris ? (
          <aside>
            <section id="bill" className="container mx-auto p-5 md:px-5 md:flex flex-col gap-7">
              <h1 className="flex justify-center items-center font-bold text-2xl p-2">Silahkan Scan QRIS Pembayaran</h1>
              <p class="flex justify-center items-center">*Mohon bayar sebelum 24jam</p>
              <div class="flex justify-center items-center">
                <img src="https://i.postimg.cc/Gh94L5Y5/qrisjon.png" alt="qris" />
              </div>
            </section>
          </aside>
        ) : (
          <div></div>
        )}

        {transfer ? (
          <aside>
            <section id="bill" className="container mx-auto p-5 md:px-5 md:flex flex-col gap-7">
              <h1 className="flex justify-center items-center font-bold text-2xl p-2">Silahkan Transfer ke Nomor Rekening yang Dituju</h1>
              <p class="flex justify-center items-center">*Mohon bayar dengan nominal yang sesuai</p>
              <div class="flex flex-col justify-center items-center text-xl font-semibold py-10">
                Bank Central Asia
                <p class="pt-4">8180159461 a/n ANTONIUS SUTANDI</p>
              </div>
              <div class="flex flex-col justify-center items-center text-xl font-semibold py-10">
                Bank Mandiri
                <p>1340007274862 a/n PIUS SARJONO</p>
              </div>
            </section>
          </aside>
        ) : (
          <div></div>
        )}

        <h2 className="flex justify-center items-center font-bold text-2xl pt-10">Setelah Membayar Mohon Upload Bukti Transfer</h2>
        <div className="bg-white p-8 rounded-md flex flex-col items-center gap-6">
          <img
            src={selectedImage}
            className="w-72 rounded-md"
            alt="Uploaded Preview"
          />
        </div>
        <div class="flex justify-center items-center pt-5 pb-1">
          <label className="flex items-center justify-center p-4 border border-gray-300 rounded-md cursor-pointer">
            <span className="text-blue-500">Choose a file</span>
            <input
              type="file"
              id="fileInput"
              className="hidden"
              onChange={handleSubmit}
            />
          </label>
        </div>

        <div class="flex justify-center items-center pt-5 pb-1">
          <button onClick={handleUpload} className="bg-slate-900 hover-bg-white hover-text-slate-900 text-white border-2 p-3 m-2 border-slate-900 rounded-xl">
            Upload Foto Bukti
          </button>
        </div>

        <h1 className="flex justify-center items-center font-bold text-2xl pt-20">Konfirmasi Pembelian</h1>
        <div class="flex justify-center items-center pt-10 pb-1">
          <button onClick={() => payment()} className="bg-slate-900 hover-bg-white hover-text-slate-900 text-white border-2 p-3 m-2 border-slate-900 rounded-xl">
            Pesan Barang
          </button>
        </div>

        <div class="flex justify-center items-center pt-1 pb-10">
          <button onClick={() => cancel()} className="bg-slate-900 hover-bg-white hover-text-slate-900 text-white border-2 p-3 m-2 border-slate-900 rounded-xl">
            Batalkan Pesanan
          </button>
        </div>
      </main>
      <FooterComponent />
    </>


  )

}

export default PaymentPageComponent;