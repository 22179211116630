import "./App.css";
import './index.css';

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Shop } from "./pages/shop/shop";
import { Cart } from "./pages/cart/cart";
import { ShopContextProvider } from "./context/shop-context";

import Home from "./pages/Home";
import LoginPage from "./pages/Login";
import Registrasi from './pages/registrasi';
import ResetPassword from './components/ResetPassword';
import LupaPassword from './pages/lupapassword';
import Acclogin from './components/AccLogin';
import { Checkout } from "./pages/Checkout";
import Dashboard from './pages/AdminDashboard';
import LoginAdmin from './pages/AdminLogin';
import Table from './pages/table';
import DetailsOrder from "./pages/DetailsOrder";
import ProductsAdmin from "./components/ProductAdmin";
import SendOrder from "./pages/TableSendView";
import PendingOrder from "./pages/TabelPendingView";
import DoneOrder from "./pages/TableDoneView";
import PaymentPage from "./pages/cart/payment";
import TransferPage from "./pages/cart/transferpayment";
import Footer from "./components/Footer";


function App() {
  return (
    <div className="App">
      <ShopContextProvider>
        <Router>
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/registrasi" element={<Registrasi />} />
            <Route path="/resetpassword" element={<ResetPassword />} />
            <Route path="/lupapassword/:id" element={<LupaPassword />} />
            <Route path="/product" element={<Shop />} />
            <Route path="/acclogin" element={<Acclogin />} />
            <Route path="/shopping_cart" element={<Cart />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/cart" element={<DetailsOrder />} />
            <Route path="/" element={<Home />} />
            <Route path="/payment" element={<PaymentPage />} />
            <Route path="/transferpayment" element={<TransferPage />} />
            <Route path="/footer" element={<Footer />} />
            
            <Route path="/login-admin" element={<LoginAdmin />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/table" element={<Table />} />
            <Route path="/pending" element={<PendingOrder />} />
            <Route path="/sendtable" element={<SendOrder />} />
            <Route path="/donetable" element={<DoneOrder />} />
            <Route path="/product_admin" element={<ProductsAdmin />} />



          </Routes>
        </Router>
      </ShopContextProvider>
    </div>
  );
}

export default App;
