import React, { useState, useEffect } from 'react';


function TanggalBulanTahun() {
    const [date, setDate] = useState(new Date());
  
    useEffect(() => {
      const timerID = setInterval(() => tick(), 1000);
      return () => {
        clearInterval(timerID);
      };
    }, []);
  
    const tick = () => {
      setDate(new Date());
    };
  
    const tanggal = date.getDate();
    const bulan = date.toLocaleString('default', { month: 'long' });
    const tahun = date.getFullYear();
  
    return (
      <div>
        <p>{tanggal} {bulan} {tahun}</p>
      </div>
    );
  }
  
  export default TanggalBulanTahun;