import { Link } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import { ShopContext } from "../context/shop-context";
import axios from "axios";

function DashboardComponent() {
  const { getDatas } = useContext(ShopContext);
  const [datas, setDatas] = useState([]);
  const Datas = getDatas()

  const handleGetData = () => {
    axios.get('http://localhost:8000/orders')
      .then(response => {
        // Respons API dalam bentuk response.data
        setDatas(response.data);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }

  useEffect(() => {
    handleGetData()
  }, [])

  const PendingProduct = datas.filter(data => data.status === "menunggu konfirmasi admin");
  const SendProduct = datas.filter(data => data.status === "Pesanan Dikirim");
  const FinishedProduct = datas.filter(data => data.status === "Pesanan Selesai");


  return (
    <div className="max-w-full mx-10 flex justify-center items-center my-20 gap-14 min-h-[500px]">
      <div className="bg-white shadow-lg rounded-lg overflow-hidden w-full sm:w-auto md:w-1/3 lg:w-1/4">
        <p className="text-gray-900 font-medium text-lg text-center my-5">{Datas.length}</p>
        <div className="flex justify-center border-2 w-[70%] mx-auto">
          <p>Product</p>
        </div>
        <div className="p-4">
          <div className="mt-4 flex justify-center">
            <Link to={"/product_admin"} className="bg-slate-500 hover:bg-slate-700 text-white font-bold py-2 px-4 rounded text-center">
              Lihat Produk
            </Link>
          </div>
        </div>
      </div>

      <div className="bg-white shadow-lg rounded-lg overflow-hidden w-full sm:w-auto md:w-1/3 lg:w-1/4">
        <p className="text-gray-900 font-medium text-lg text-center my-5">{PendingProduct.length}</p>
        <div className="flex justify-center border-2 w-[70%] mx-auto">
          <p>Pesanan Pending</p>
        </div>
        <div className="p-4">
          <div className="mt-4 flex justify-center">
            <Link to={"/pending"} className="bg-slate-500 hover:bg-slate-700 text-white font-bold py-2 px-4 rounded text-center">
              Lihat Pesanan Pending
            </Link>
          </div>
        </div>
      </div>

      <div className="bg-white shadow-lg rounded-lg overflow-hidden w-full sm:w-auto md:w-1/3 lg:w-1/4">
        <p className="text-gray-900 font-medium text-lg text-center my-5">{SendProduct.length}</p>
        <div className="flex justify-center border-2 w-[70%] mx-auto">
          <p>Pesanan Dikirim</p>
        </div>
        <div className="p-4">
          <div className="mt-4 flex justify-center">
            <Link to={"/sendtable"} className="bg-slate-500 hover:bg-slate-700 text-white font-bold py-2 px-4 rounded text-center">
              Lihat Pesanan Dikirim
            </Link>
          </div>
        </div>
      </div>

      <div className="bg-white shadow-lg rounded-lg overflow-hidden w-full sm:w-auto md:w-1/3 lg:w-1/4">
        <p className="text-gray-900 font-medium text-lg text-center my-5">{FinishedProduct.length}</p>
        {/* <img className="w-full h-56 object-cover rounded-lg" src="./img/meja.jpeg" alt="Gambar Card" /> */}
        <div className="flex justify-center border-2 w-[70%] mx-auto">
          <p>Pesanan Selesai</p>
        </div>
        <div className="p-4">
          <div className="mt-4 flex justify-center">
            <Link to={"/donetable"} className="bg-slate-500 hover:bg-slate-700 text-white font-bold py-2 px-4 rounded text-center">
              Lihat Pesanan Selesai
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardComponent;
