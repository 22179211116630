import { createContext, useEffect, useState } from "react";
import axios from "axios";

export const ShopContext = createContext(null);

const getDefaultCart = (productCount) => {
  let cart = {};
  for (let i = 1; i <= productCount; i++) {
    cart[i] = 0;
  }
  return cart;
};

export const ShopContextProvider = (props) => {
  // const [cartItems, setCartItems] = useState(getDefaultCart());
  const [cartItems, setCartItems] = useState(() => {
    const storedCart = localStorage.getItem('cartItems');
    return storedCart ? JSON.parse(storedCart) : getDefaultCart();
  });

  const [datas, setDatas] = useState([])
  // const [isLogedIn, setIsLogedIn] = useState(false)
  const [isLogedIn, setIsLogedIn] = useState(() => {
    const storedValue = localStorage.getItem('isLoggedIn');
    return storedValue === 'true' ? true : false;
  });
  useEffect(() => {
    localStorage.setItem('isLoggedIn', isLogedIn);
  }, [isLogedIn]);
  useEffect(() => {
    localStorage.setItem('cartItems', JSON.stringify(cartItems));
  }, [cartItems]);

  // const [name, setName] = useState("")
  // const [email, setEmail] = useState("")
  // const [phoneNumber, setPhoneNumber] = useState("")
  // const [address, setAddress] = useState("")

  const [name, setName] = useState(() => {
    return localStorage.getItem('name') || "";
  });

  const [email, setEmail] = useState(() => {
    return localStorage.getItem('email') || "";
  });

  const [phoneNumber, setPhoneNumber] = useState(() => {
    return localStorage.getItem('phoneNumber') || "";
  });

  const [address, setAddress] = useState(() => {
    return localStorage.getItem('address') || "";
  });

  // Use useEffect to save changes to localStorage
  useEffect(() => {
    localStorage.setItem('name', name);
  }, [name]);

  useEffect(() => {
    localStorage.setItem('email', email);
  }, [email]);

  useEffect(() => {
    localStorage.setItem('phoneNumber', phoneNumber);
  }, [phoneNumber]);

  useEffect(() => {
    localStorage.setItem('address', address);
  }, [address]);


  const getAddress = async () => {
    try {
      const response = await axios.get('http://localhost:8000/register')
      // Respons API dalam bentuk response.data
      setAddress(response.data.address);
      console.log(response.data.address);
    }
    catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    // getAddress();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('http://localhost:3004/products');
        setDatas(response.data);

        // Update cartItems based on the number of products
        const productCount = response.data.length;
        setCartItems(getDefaultCart(productCount));
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  const sendEmailResetPassword = async (params) => {
    try {
      const response = await axios.post('http://localhost:8000/resetpassword', params)
      return response
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  const updateEmailResetPassword = async (params) => {
    try {
      const response = await axios.patch('http://localhost:8000/resetpassword', params)
      return response
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  const cekLogin = async (params) => {
    try {
      // Kirim permintaan GET ke server dengan kredensial yang dikirim oleh pengguna
      const response = await axios.post('http://localhost:8000/login', params)
      if (response.data.message === 'Login successful') {
        setName(response.data.name)
        setEmail(response.data.email)
        setPhoneNumber(response.data.phone_number)
        setAddress(response.data.address)
        setIsLogedIn(true);
        // Kembalikan true untuk menandakan bahwa login berhasil
        return true;
      } else {
        // Jika tidak ada data yang cocok, kembalikan false untuk menandakan login gagal
        return false;
      }
    } catch (error) {
      console.error(error);
      // Kembalikan false jika terjadi kesalahan dalam permintaan HTTP
      return false;
    }
  };

  const payment = () => {

  }


  const logout = () => {
    setIsLogedIn(false);
    setCartItems(false);
  };

  const emptyCart = () => {
    setCartItems(getDefaultCart());
  };

  const Datas = datas.map((data) => ({
    ...data,
    status: "sudah dibayar" // Anda bisa mengganti 'newValue' dengan nilai yang Anda inginkan.
  }));

  const getTotalCartItem = () => {
    let totalItems = 0;
    for (const item in cartItems) {
      totalItems += cartItems[item];
    }
    return totalItems;
  };

  const getTotalCartAmount = () => {
    let totalAmount = 0;
    for (const item in cartItems) {
      if (cartItems[item] > 0) {
        let itemInfo = Datas.find((product) => product.id == Number(item));
        if (itemInfo) {
          totalAmount += cartItems[item] * itemInfo.price;
        }
      }
    }
    
    return totalAmount;
  };

  const getCartName = () => {
    let cartName = [];
    for (const item in cartItems) {
      if (cartItems[item] > 0) {
        let itemInfo = Datas.find((product) => product.id == Number(item));
        if (itemInfo) {
        cartName.push({ name: itemInfo.name, img_url: itemInfo.img_url, id: itemInfo.id });
        }
      }
    }
    return cartName;
  };

  const getTotalCartWeight = () => {
    let totalWeight = 0;
    for (const item in cartItems) {
      if (cartItems[item] > 0) {
        let itemInfo = Datas.find((product) => product.id == Number(item));
        if (itemInfo) {
          totalWeight += cartItems[item] * itemInfo.weight;
        }
      }
    }
    return totalWeight;
  };

  const getTotalItemPrice = () => {
    let totalPrice = 0;
        for (const item in cartItems) {
      if (cartItems[item] > 0) {
        let itemInfo = Datas.find((product) => product.id == Number(item));
        if (itemInfo) {
          totalPrice += cartItems[item] * itemInfo.price + itemInfo.price * 0.1 + 80000;
        }
      }
    }
    
    return totalPrice;
  };

  // const addToCart = (itemId) => {
  //   setCartItems((prev) => ({ ...prev, [itemId]: prev[itemId] + 1 }));
  // };
  const addToCart = (itemId) => {
    setCartItems((prev) => {
      return { ...prev, [itemId]: (prev[itemId] || 0) + 1 };
    });
  };

  const removeFromCart = (itemId) => {
    setCartItems((prev) => ({ ...prev, [itemId]: prev[itemId] - 1 }));
  };

  const updateCartItemCount = (newAmount, itemId) => {
    setCartItems((prev) => ({ ...prev, [itemId]: newAmount }));
  };

  const checkout = () => {
    setCartItems(getDefaultCart());
  };

  const getTotalProduct = () => {
    let total = Datas.length
    return total;
  };

  const getDatas = () => {
    return Datas
  };



  const contextValue = {
    cartItems,
    addToCart,
    sendEmailResetPassword,
    updateCartItemCount,
    removeFromCart,
    getTotalCartAmount,
    getTotalCartWeight,
    getTotalItemPrice,
    checkout,
    getTotalProduct,
    getDatas,
    getCartName,
    getTotalCartItem,
    updateEmailResetPassword,
    cekLogin,
    isLogedIn,
    logout,
    name,
    address,
    email,
    phoneNumber,
    payment,
    emptyCart
  };

  return (
    <ShopContext.Provider value={contextValue}>
      {props.children}
    </ShopContext.Provider>
  );
};