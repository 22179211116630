import React, { useContext } from 'react';
import NavComponent from '../../components/Navigasi'
import FooterComponent from '../../components/Footer'
import { ShopContext } from '../../context/shop-context';


import { Link } from "react-router-dom";
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import "./shop.css"
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

export const Shop = () => {
  const { addToCart, cartItems, getDatas, isLogedIn } = useContext(ShopContext);
  
  const Datas = getDatas();
  function formatRupiah(number) {
    return new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(number);
  }
  
  const renderDataBytype = (type) => {
    
    const filteredData = Datas.filter((data) => data.type === type);
    
    return (
      <>
      <p className='font-semibold uppercase mt-10 mx-auto text-xl lg:text-center text-left'>{type}</p>
      <Swiper
      // install Swiper modules
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        spaceBetween={50}
        slidesPerView={3}
        navigation
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
      >
      {
        
      filteredData.map((data) => {
      const cartItemCount = cartItems[data.id];

      return(

      <SwiperSlide className='lg:my-10 mb-20 mt-5' key={data.id}>
        <div className="relative w-full sm:w-[80%] md:w-[60%] lg:w-[50%]">
        <img className='w-[200px] h-[300px] object-cover' src={data.img_url} alt={data.name} />            
        <p className="font-bold py-1">{data.name}</p>
            <p className="py-1">{data.desc}</p>
            <p className="font-semibold py-1">{formatRupiah(data.price)}</p>
          <Link onClick={() => {
            isLogedIn? addToCart(data.id) : alert("Silakan Untuk Login Terlebih Dahulu")
          }


        }>
            <img
              className="w-8 h-8 absolute bottom-0 right-0 hover:cursor-pointer"
              src="./img/cart.png"
              alt="Cart"
              />
              {cartItemCount > 0 && <> ({cartItemCount})</>}
          </Link>
        </div>
      </SwiperSlide>
      )
    })
  }
  </Swiper>
      </>

    );
  };

  return (
    <>
        <NavComponent/>
          <div className='swiper-container mx-auto w-4/5 my-5 min-h-screen'>
          <p className='font-semibold text-xl ml-5 text-center lg:text-left my-10'>TELUSURI KOLEKSI KAMI</p>
              <div className='lg:my-10 mb-10 mt-5'>
                <div>
                  {renderDataBytype('kursi')}
                  {renderDataBytype('meja')}
                  {renderDataBytype('lemari')}
                </div>
              </div>
        </div>
        <FooterComponent />
    </>

  );
}