import RegistrasiComponent from "../components/Registrasi";
import FooterComponent from "../components/Footer";
import NavComponent from "../components/Navigasi";
function Registrasi (){
    return (
        <>
        {/* <NavComponent/> */}

        <RegistrasiComponent/>

        <FooterComponent/>
        </>
    )
}
export default Registrasi;