import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../components/loader";

export const Checkout = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulasikan pengambilan data dari API atau sumber lain
    setTimeout(() => {
      // Set data yang diambil dari sumber
      setIsLoading(false); // Berhenti menampilkan loading setelah data diambil
    }, 2000); // Waktu simulasi dalam milidetik
  }, []);
  const navigate = useNavigate();

  return (
      <main>
        {
          isLoading? (
            <Loader />
          )
          : (
            <div className="w-full h-screen flex flex-col items-center justify-center fixed">
              <p className="pb-5">Thanks For Your Order(s)❤️</p>
              <button className="bg-slate-900 text-white p-3 rounded-xl" onClick={() => navigate("/product")}>Back To Shopping </button>
            </div>
            

          )
        }
      </main>
  )
};
