import React, { useContext } from "react";
import axios from 'axios';

import { ShopContext } from "../../context/shop-context";
import { useNavigate } from 'react-router-dom';
import NavComponent from "../../components/Navigasi";


function TransferPageComponent () {
  const {name, address, cartItems, getTotalCartAmount, getTotalCartWeight, getTotalItemPrice, getCartName, emptyCart} = useContext(ShopContext);

  const totalAmount = getTotalCartAmount();
  const totalWeight = getTotalCartWeight();
  const totalPrice = getTotalItemPrice();
  const barang = getCartName();

  const generateRandomNumber = () => {
  return Math.floor(Math.random() * 10000);
  };

  function formatRupiah(number) {
    return new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(number);
  }

  const sendOrderDataToApi = () => {
    const orderData = barang.map(item => {
      const order = {
        id: generateRandomNumber(),
        goods_id: item.id,
        username: name,
        alamat: address,
        jumlah_barang: cartItems[item.id],
        harga: totalAmount,
        berat: totalWeight,
        total: formatRupiah(totalPrice),
        barang: item.name,
        img_url: item.img_url,
        status: "menunggu konfirmasi admin"
      }

      return axios.post('http://localhost:3004/orders', order)
        .then(response => {
          console.log('Post created:', response.data);
          console.log(order);
        })
        .catch(error => {
          console.error('Error:', error);
        });
    });
    return orderData;
  };

  const navigate = useNavigate();

  const cancel = () => {
    navigate ("/shopping_cart")
    alert("Silahkan Melihat Produk Kami Lagi");
      }

  const handleSubmit = () => {
    
           }

  const payment = async () => {
    await sendOrderDataToApi();
    await emptyCart();
    navigate ("/cart")
      }


  return (
      <>
        <NavComponent/>
        <main className="min-h-[700px]">
        <h1 className="flex justify-center items-center font-bold text-2xl p-5">Silahkan Transfer ke Nomor Rekening yang Dituju</h1>
        <p class="flex justify-center items-center">*Mohon bayar dengan nominal yang sesuai</p>
          <div class="flex flex-col justify-center items-center text-xl font-semibold py-10">
            Bank Central Asia 
            <p class="pt-4">1342531517 a/n DIONNISIUS LUKY PRATAMA</p>
          </div>
          <div class="flex flex-col justify-center items-center text-xl font-semibold py-10">
            Bank Mandiri
            <p>ppp</p>  
          </div>

        <h2 className="flex justify-center items-center font-bold text-2xl pt-10">Setelah Membayar Mohon Upload Bukti Transfer</h2>
        <div class="flex justify-center items-center pt-8">
        <button onClick={handleSubmit} type="submit" className="bg-slate-400 px-5 py-2 rounded-full text-white font-semibold hover:bg-slate-300 active:bg-slate-800 focus:ring-black">
          Upload Bukti Transfer
        </button>
        </div>

        <div class="flex justify-center items-center pt-5 pb-10">      
          <button onClick={() => payment()} className="bg-slate-400 px-5 py-2 rounded-full text-white font-semibold hover:bg-slate-300 active:bg-slate-800 focus:ring-black">
            Pesan Barang
          </button>
        </div>

        <div class="flex justify-center items-center pt-5 pb-10">      
          <button onClick={() => cancel()} className="bg-slate-400 px-5 py-2 rounded-full text-white font-semibold hover:bg-slate-300 active:bg-slate-800 focus:ring-black">
            Batalkan Pesanan
          </button>
        </div>



        </main>
      </>

      
    )

}

export default TransferPageComponent;