import NavComponent from "../components/Navigasi2";
import TableComponent from "../components/Table";

function Table(){
    return (
        <>
        <NavComponent />
        <TableComponent/>
        </>
    )
}
export default Table;