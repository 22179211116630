import { Link } from "react-router-dom";
import { useState } from "react";
import { BiMenu } from 'react-icons/bi'
import { IoMdClose } from 'react-icons/io'

const admin = "ADMIN"

function NavComponent() {
  const [nav, setNav] = useState(false);

  function handleNav() {
      setNav(!nav);
  };

    return (
      <>
        <nav className="bg-white border-gray-200 dark-bg-gray-900 mt-3 w-full mx-auto hidden lg:block">
        {/* md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700"> */}
       <div className="max-w-[90%] flex flex-wrap items-center justify-between mx-auto p-4">
           <Link to={"/"} className="flex items-center">
               <img className="w-14 mx-5" src="/logo/logo2.png" alt="" />
                <span className="self-center text-2xl font-semibold whitespace-nowrap text-slate-700">ADMIN</span>
           </Link>
           <div className="hidden w-full md:block md:w-auto">
               <ul className="font-medium flex flex-col md:p-4 p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-white ">
                   <li>
                     <Link to={"/dashboard"} className="block py-2 pl-3 pr-4 text-slate-700 bg-blue-700 rounded md:bg-transparent md:p-0  text-lg md:hover:text-blue-700" aria-current="page">Home</Link>
                   </li>
                   <li>
                     <Link to={"/table"} className="block py-2 pl-3 pr-4 text-slate-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0  md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent text-lg">Orders</Link>
                   </li>
                   <li>
                     <Link to={"/login-admin"} className="block py-2 pl-3 pr-4 text-slate-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0  md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent text-lg">Log out</Link>
                   </li>
                 </ul>
           </div>
       </div>
   </nav>

   {/* responsive */}
   <nav className="sticky top-0 bg-slate-50 lg:hidden h-14  z-20">
      <div className="flex justify-between mx-5 items-center">
        <Link to={"/"} className="flex items-center">
                <img className="w-14 mx-5" src="/logo/logo2.png" alt="" />
                <span className="self-center text-2xl font-semibold whitespace-nowrap text-slate-700">ADMIN</span>
        </Link>
        <div className='p-3 mr-[-20px] cursor-pointer'>
           {
            !nav? ( <BiMenu onClick={handleNav} size={30} /> ) : ( <IoMdClose onClick={handleNav} size={30} /> )
}
        </div>  
      </div>
                <div className={!nav? " flex relative left-[-1000px] h-screen ease-in duration-300 flex-col bg-slate-200 items-center justify-center" : " flex relative left-0 h-screen ease-in duration-300 flex-col  bg-slate-200 items-center justify-center backdrop-blur-lg"}>
                  
                      <Link onClick={handleNav} to={"/dashboard"} className="block text-center p-10 w-full text-slate-700 bg-slate-200 hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0  md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent text-2xl" aria-current="page">Home</Link>
                    
                    
                      <Link onClick={handleNav} to={"/table"} className="block text-center p-10 w-full text-slate-700 bg-slate-200 hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0  md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent text-2xl">Orders</Link>
                    
                    
                      <Link onClick={handleNav} to={"/login-admin"} className="block text-center p-10 w-full text-slate-700 bg-slate-200 hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0  md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent text-2xl">Log Out</Link>
                    
                    
                    
                </div>
            </nav>
      </>
    )
}
export default NavComponent;