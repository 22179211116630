import { Link } from "react-router-dom";
import { useState, useContext } from "react";
import { BiMenu } from 'react-icons/bi'
import { IoMdClose } from 'react-icons/io'

import { ShopContext } from "../context/shop-context";

function NavComponent () {
  const {getTotalCartItem, isLogedIn, logout, name, payment} = useContext(ShopContext);
  const [nav, setNav] = useState(false);
  const totalCart = getTotalCartItem();


    function handleNav() {
        setNav(!nav);
    };


    function logoutQestion() {
      // eslint-disable-next-line no-restricted-globals
      let result = confirm("Apakah Kamu Yakin Ingin Logout??")
      result ? logout() : alert("Terima Kasih")
    }

    return (
      <>
        <nav className="hidden lg:block bg-slate-100 border-gray-800 border-b-2 dark-bg-gray-900 w-full sticky top-0 z-20">
         {/* md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700"> */}
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
            <Link to={"/"} className="flex items-center lg:ml-5">
                <img className="w-14 mx-5" src="/logo/logo2.png" alt="" />
                <span className="self-center text-3xl font-semibold whitespace-nowrap text-slate-700">JONI GENCAR ABYAKTA</span>
            </Link>
            <div className="hidden w-full md:block md:w-auto lg:mr-5 ">
                <ul className="font-medium flex flex-col md:p-4 p-0 mt-4 border border-gray-100 rounded-lg bg-slate-200 md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-slate-100 ">
                    <li>
                      <Link to={"/"} className="block py-2 pl-3 pr-4 text-slate-700 bg-blue-700 rounded md:bg-transparent md:p-0  text-lg" aria-current="page">Home</Link>
                    </li>
                    <li>
                      <Link to={"/product"} className="block py-2 pl-3 pr-4 text-slate-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0  md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent text-lg">Product</Link>
                    </li>
                    <li>
                      <Link to={"/shopping_cart"} className="block py-2 pl-3 pr-4 text-slate-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0  md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent text-lg">Shopping Cart  ( {totalCart > 0 && logout && isLogedIn && payment ? (totalCart) : ""} )</Link>
                    </li>
                    <li>
                      <Link to={"/cart"} className="block py-2 pl-3 pr-4 text-slate-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0  md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent text-lg ">Cart History</Link>
                    </li>
                    {
                      isLogedIn? (
                        <li>
                          <Link onClick={logoutQestion} className="block py-2 pl-3 pr-4 text-slate-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0  md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent text-lg">Logout</Link>
                        </li>
                       ) : (
                        <Link to={"/login"} className="block py-2 pl-3 pr-4 text-slate-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0  md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent text-lg">Login</Link>
                       )
                    }
                  </ul>
            </div>
        </div>
    </nav>

    {/* responsive */}
    <nav className="sticky top-0 bg-slate-50 lg:hidden h-14  z-20">
      <div className="flex justify-between mx-5 items-center">
        <Link to={"/"} className="flex items-center">
                <div className="logo w-10 h-10 bg-slate-800 mx-3"></div>
                <span className="self-center text-2xl font-semibold whitespace-nowrap ">Luck-Shop</span>
        </Link>
        <div className='p-3 mr-[-20px] cursor-pointer'>
           {
            !nav? ( <BiMenu onClick={handleNav} size={30} /> ) : ( <IoMdClose onClick={handleNav} size={30} /> )
}
        </div>  
      </div>
                <div className={!nav? " flex relative left-[-1000px] h-screen ease-in duration-300 flex-col bg-slate-200 items-center justify-center" : " flex relative left-0 h-screen ease-in duration-300 flex-col  bg-slate-200 items-center justify-center backdrop-blur-lg"}>
                  
                      <Link onClick={handleNav} to={"/"} className="block text-center p-10 w-full text-slate-700 bg-slate-200 hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0  md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent text-2xl" aria-current="page">Home</Link>
                    
                    
                      <Link onClick={handleNav} to={"/product"} className="block text-center p-10 w-full text-slate-700 bg-slate-200 hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0  md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent text-2xl">Product</Link>
                    
                    
                      <Link onClick={handleNav} to={"/shopping_cart"} className="block text-center p-10 w-full text-slate-700 bg-slate-200 hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0  md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent text-2xl">Shopping Cart</Link>
                    
                      <Link onClick={handleNav} to={"/cart"} className="block text-center p-10 w-full text-slate-700 bg-slate-200 hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0  md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent text-2xl">Cart</Link>
                    
                      <Link onClick={logoutQestion} className="block text-center p-10 w-full text-slate-700 bg-slate-200 hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0  md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent text-2xl">Log Out</Link>
                    
                </div>
            </nav>

      </>
    )
}
export default NavComponent;