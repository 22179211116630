import NavComponent from "../components/Navigasi2";
import DashboardComponent from "../components/dashboard";
import FooterComponent from "../components/Footer";

function AdminDashboard(){
    return (
        <>
        <NavComponent/>
        <DashboardComponent/>

       <FooterComponent/>


        </>
    )
}
export default AdminDashboard;