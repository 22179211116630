import axios from "axios";
import React, { useState, useEffect } from "react";
import NavComponent from "./Navigasi2";
import FooterComponent from "./Footer";


export default function ProductsAdmin () {
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('http://localhost:3004/products');
                setData(response.data);
            } catch (error) {
                if (error.response?.status === 404) {
                    // Handle 404 error
                } else {
                    // Handle other errors
                }
            }
        };

        fetchData();
    }, []);


    return (
        <>
        <NavComponent/>
        <div className="p-5 grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
            {
                data.map(product => (
                    <div class="mb-5 mx-8 max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                        <img class="w-full h-[240px] object-cover rounded-t-lg" src={product.img_url} alt={product.name} />
                    
                        <div class="p-5">
                            
                            <h5 class="mb-2 text-2xl font-bold tracking-tight">{product.name}</h5>
                            <p class="mb-3 font-normal text-green-400 text-2xl">stock: {product.stock}</p>

                        </div>
                    </div>
                ))
            }
            
        </div>
        <FooterComponent/>
        </>
        
    )
}
