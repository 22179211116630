import NavComponent from "../components/Navigasi2";
import TablePending from "../components/TablePending";

function PendingOrder () {
    return (
        <>
        <NavComponent/>
        <TablePending />
        </>
    )
}

export default PendingOrder